import { Link } from "react-router-dom";
import React from "react";
import BGImg from "../../assets/img/bg/footer-bg.png";
import Img1 from "../../assets/img/logo/f_logo.png";
import Img2 from "../../assets/img/icon/ft-icon01.png";
import Footer from "../../assets/img/icon/ft-icon02.png";
import Footer1 from "../../assets/img/icon/ft-icon03.png";

const Main = () => {
  return (
    <>
      <footer
        className="footer-bg footer-p pt-20"
        style={{ alignItems:"center",backgroundColor: "#7fb8cb"}}
      >
       
                            
             
             
             
                 
                  
            
                  
       
              <div className="col-lg-6">
                <div
                  className="copy-text"
                  style={{fontWeight: "bold", color: "black" }}
                >
                  Copyright &copy; 2023Jonathan Allison. All rights reserved.
                </div>
              </div>  .
      </footer>
    </>
  );
};

export default Main;
