import React from 'react'
import Bredcrumb from '../HomeMain/Bredcrumb'
import Appointment from '../HomeMain/Appointment'
import Testimonial from '../HomeMain/Testimonial'
import Service from '../HomeMain/Service'

const Main = () => {
  return (
    <>
      <Bredcrumb
       />
     
        <Service />
      
      
    </>
  )
}

export default Main